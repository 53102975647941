<template>
  <div class="flex">
    <div
      v-show="upload"
      class="upload"
    >
      <div>
        <div
          @click="upload = !upload"
          style="width: 25px;"
          class="mr-4 ml-auto"
        >
          <r-icon
            class="mr-2 margin-right"
            icon="close"
            size="20"
            fill="rocky"
          />
        </div>
        <div class="parent align-center">
          <div class="container-upload">
            <div
              style="display: table;"
              class="mb-6"
            >
              <r-icon
                class="mr-2 margin-right"
                icon="selected"
                size="32"
                fill="matrix"
              />
            </div>
            <h1 class="ricotta mb-1">
              Событие добавлено
            </h1>
            <div class="date">
              Событие добавлено в разделе «Дорожные события».
            </div>
            <div class="flex mt-8 mb-4">
              <r-button
                type-button="button"
                size="helike"
                width="wide"
                type="secondary"
                color="rocky"
                title="Отлично"
                @click="close()"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Save',
  components: {
  },
  data() {
    return {
      upload: false
    };
  },
  created() {
    if (this.$route.query.dtp == 'true') {
      this.upload = true;
    }
  },
  methods: {
    close() {
      this.upload = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.date {
  font-family: Golos UI;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #04153E;
  opacity: 0.48;
}

.align-center {
  align-items: center;
  margin-top: 5px;
}

.upload {
  background-color: white;
  z-index: 999;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  overflow-y: scroll;
}
.upload::-webkit-scrollbar-track {
  width: 4px;
  background: #FFFFFF;
}

.upload::-webkit-scrollbar {
  width: 4px;
  background: #e4edfb;
  border-radius: 2px;
  opacity: 0.72;
}

.upload::-webkit-scrollbar-thumb {
  width: 4px;
  background: #e4edfb;
  border-radius: 2px;
  opacity: 0.72;
}

.parent {
  display: block;
  position: absolute;
  width: 40%;
  height: 400px;
  top: 33%;
  margin-left: 31%;
}
.margin-right {
  margin-top: 17px;
  margin-right: 17px;
  z-index: 1000;
  cursor: pointer;
}

.clear {
  height: 56px;
  background: #3D75E4;
  opacity: 0.08;
  border-radius: 8px;
}

.notSave {
  width: 100%;
  display: inline-block;
}
.objects {
  &__filter {
    display: flex;
  }
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('r-uploader',{attrs:{"title":_vm.title,"sub-title":_vm.subTitle,"accept":_vm.acceptFile,"callback":_vm.uploadFile,"max-files":_vm.countFile,"button-simple-option":{
      icon: 'delete',
      color: 'fargo'
    }},model:{value:(_vm.filesUpload),callback:function ($$v) {_vm.filesUpload=$$v},expression:"filesUpload"}}),_c('div',{staticClass:"mt-6 flex flex-direction-column"},_vm._l((_vm.value),function(file,index){return _c('r-file',{key:index,staticClass:"mb-6",attrs:{"file":file,"title":file.name,"icon-preview":file.icon == 'image' ? null : 'car',"button-simple-option":{
        icon: 'delete',
        color: 'fargo'
      }},on:{"actions":_vm.deleteFile,"clickPreview":function($event){return _vm.openModalGallery(file, index)}}})}),1),_c('r-modal',{ref:"modal",attrs:{"close-icon":""}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
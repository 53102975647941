<template>
  <div>
    <div
      class="table"
    >
      <scroll-bar
        v-if="filteredList.length > 0"
        :options="{ alwaysShowTracks: true }"
      >
        <div
          class="table_head"
          v-if="headings.length > 0"
          ref="tableHead"
        >
          <div
            class="table_row mozzarella"
            :style="
              !!columnsGrid
                ? 'grid-template-columns:' + columnsGrid.join(' ')
                : 'grid-auto-flow:column;'
            "
          >
            <div
              class="table_col opacity-48 pointer flex align-center"
              v-for="(head, index) of headings"
              :key="'head' + index"
              @click="sortingClick(head.field)"
            >
              {{ head.title }}
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M8.37829 11.835C8.16934 12.055 7.83058 12.055 7.62163 11.835L5.15665 9.96155C4.94771 9.74158 4.94771 9.38494 5.15665 9.16498C5.3656 8.94501 5.70436 8.94501 5.91331 9.16498L7.99996 10.6402L10.0866 9.16497C10.2956 8.94501 10.6343 8.94501 10.8433 9.16497C11.0522 9.38494 11.0522 9.74158 10.8433 9.96154L8.37829 11.835Z"
                  :fill="currentSortDir == 'desc' && currentSort == head.field ? '#3D75E4': '#C0D6F6'"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M7.6217 4.16497C7.83064 3.94501 8.16939 3.94501 8.37832 4.16497L10.8433 6.03846C11.0522 6.25842 11.0522 6.61506 10.8433 6.83503C10.6344 7.05499 10.2956 7.05499 10.0867 6.83503L8.00001 5.35983L5.91332 6.83503C5.70439 7.05499 5.36564 7.05499 5.1567 6.83503C4.94777 6.61506 4.94777 6.25842 5.1567 6.03846L7.6217 4.16497Z"
                  :fill="currentSortDir == 'asc' && currentSort == head.field ? '#3D75E4': '#C0D6F6'"
                />
              </svg>
            </div>
          </div>
        </div>
        <div
          class="table_body"
          :key="count"
          v-if="filteredList.length"
        >
          <div
            class="table_row"
            v-for="(row, index) of filteredList"
            :style="
              !!columnsGrid
                ? 'grid-template-columns:' + columnsGrid.join(' ')
                : 'grid-auto-flow:column;'
            "
          >
            <div
              v-for="(col, colIndex) of row"
              :style="colIndex != 'public' ? 'height: 100%; display: table;': ''"
            >
              <div
                class="pointer"
                v-if="colIndex != 'public'"
                style="display	: table-cell;vertical-align	: middle;"
                @click="link(row.public.items.id)"
              >
                <div
                  class="table_col flex align-center feta"
                >
                  <div v-if="colIndex == 'photo'">
                    <div
                      v-if="col != ''"
                      class="photo"
                    >
                      <img
                        :src="col"
                        class="photo_img"
                      >
                    </div>
                    <svg
                      v-else-if="filteredList[index]['type'].id == 0"
                      width="76"
                      height="47"
                      viewBox="0 0 76 47"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        width="76"
                        height="47"
                        fill="#E4EDFB"
                      />
                      <path
                        d="M32.8399 16.4506L31.5651 20.9121C31.3811 21.556 30.7926 22 30.1228 22H29C28.4477 22 28 21.5523 28 21C28 20.4477 28.4477 20 29 20H29.7457L30.9168 15.9011C31.4074 14.1839 32.977 13 34.7629 13H41.2371C43.023 13 44.5926 14.1839 45.0832 15.9011L46.2543 20H47C47.5523 20 48 20.4477 48 21C48 21.5523 47.5523 22 47 22H45.8772C45.2074 22 44.6189 21.556 44.4349 20.9121L43.1601 16.4506C42.9148 15.592 42.1301 15 41.2371 15H40.5C40.5 15.5523 40.0523 16 39.5 16H36.5C35.9477 16 35.5 15.5523 35.5 15H34.7629C33.8699 15 33.0852 15.592 32.8399 16.4506Z"
                        fill="#3D75E4"
                        fill-opacity="0.6"
                      />
                      <path
                        opacity="0.6"
                        d="M34 26C34 25.4477 34.4477 25 35 25H41C41.5523 25 42 25.4477 42 26C42 26.5523 41.5523 27 41 27H35C34.4477 27 34 26.5523 34 26Z"
                        fill="#3D75E4"
                      />
                      <path
                        opacity="0.6"
                        d="M30 25.3337V27.938L30.3828 31.0003H33V30.0003C33 29.448 33.4477 29.0003 34 29.0003H42C42.5523 29.0003 43 29.448 43 30.0003V31.0003H45.6172L46 27.938V25.3877L44.3162 25.9489C43.7923 26.1236 43.226 25.8404 43.0513 25.3165C42.8767 24.7925 43.1598 24.2262 43.6838 24.0516L46.0257 23.271C46.997 22.9472 48 23.6701 48 24.694V28.0003C48 28.0417 47.9974 28.0832 47.9923 28.1243L47.6018 31.2483C47.4767 32.2492 46.6259 33.0003 45.6172 33.0003H43C41.8954 33.0003 41 32.1048 41 31.0003H35C35 32.1048 34.1046 33.0003 33 33.0003H30.3828C29.3741 33.0003 28.5233 32.2492 28.3982 31.2483L28.0077 28.1243C28.0026 28.0832 28 28.0417 28 28.0003V24.667C28 23.6669 28.96 22.9469 29.9201 23.227L32.28 23.9154C32.8102 24.0701 33.1146 24.6252 32.96 25.1554C32.8053 25.6856 32.2502 25.99 31.72 25.8354L30 25.3337Z"
                        fill="#3D75E4"
                      />
                    </svg>
                    <svg
                      v-else-if="filteredList[index]['type'].id == 1"
                      width="76"
                      height="47"
                      viewBox="0 0 76 47"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_2_56)">
                        <g clip-path="url(#clip1_2_56)">
                          <rect
                            width="76"
                            height="47"
                            fill="#E4EDFB"
                          />
                        </g>
                      </g>
                      <rect
                        width="76"
                        height="47"
                        fill="#E4EDFB"
                      />
                      <g opacity="0.6">
                        <path
                          d="M35.2332 14.06C35.9867 12.6467 38.0133 12.6467 38.7668 14.06L46.7621 29.058C47.4726 30.3908 46.5063 31.9999 44.9953 31.9999H44.2471C43.6943 31.9999 43.2462 31.552 43.2462 30.9994C43.2462 30.4469 43.6943 29.9989 44.2471 29.9989H44.9953L37 15.001L29.0047 29.9989L40.6236 29.9989C41.1764 29.9989 41.6245 30.4469 41.6245 30.9994C41.6245 31.552 41.1764 31.9999 40.6236 31.9999H29.0047C27.4937 31.9999 26.5274 30.3908 27.2379 29.058L35.2332 14.06Z"
                          fill="#3D75E4"
                        />
                        <path
                          d="M36.9999 18.9935C37.5527 18.9935 38.0009 19.4415 38.0009 19.994V23.996C38.0009 24.5485 37.5527 24.9965 36.9999 24.9965C36.4471 24.9965 35.9989 24.5485 35.9989 23.996V19.994C35.9989 19.4415 36.4471 18.9935 36.9999 18.9935Z"
                          fill="#3D75E4"
                        />
                        <path
                          d="M37 28.4202C37.6695 28.4202 38.2122 27.8777 38.2122 27.2086C38.2122 26.5394 37.6695 25.9969 37 25.9969C36.3305 25.9969 35.7878 26.5394 35.7878 27.2086C35.7878 27.8777 36.3305 28.4202 37 28.4202Z"
                          fill="#3D75E4"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_2_56">
                          <rect
                            width="76"
                            height="47"
                            fill="white"
                          />
                        </clipPath>
                        <clipPath id="clip1_2_56">
                          <rect
                            width="76"
                            height="47"
                            fill="white"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <div v-if="colIndex == 'type'">
                    <div>
                      {{ col.name }}
                    </div>
                    <div class="types mt-1">
                      {{ col.type }}
                    </div>
                  </div>
                  <div v-if="colIndex == 'address'">
                    <div class="over">
                      {{ col }}
                    </div>
                  </div>
                  <div v-if="colIndex == 'start'">
                    <div>
                      {{ col.date }}
                    </div>
                    <div class="types mt-1">
                      {{ col.time }}
                    </div>
                  </div>
                  <div v-if="colIndex == 'status'">
                    <svg
                      v-if="col === 0"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      class="ml-3"
                    >
                      <circle
                        cx="12"
                        cy="12"
                        r="12"
                        fill="#81ABEE"
                      />
                      <path
                        d="M13.7123 15.856H9.96831L9.20031 18H6.48031L10.5603 6.8H13.4403L17.5203 18H14.4803L13.7123 15.856ZM10.7843 13.648H12.8963L11.8403 10.32L10.7843 13.648Z"
                        fill="white"
                      />
                    </svg>
                    <svg
                      v-if="col === 1"
                      class="ml-3"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="12"
                        cy="12"
                        r="12"
                        fill="#A2ABBE"
                      />
                      <path
                        d="M7.04188 6.8H9.92188V11.056H14.0819V6.8H16.9619V18H14.0819V13.424H9.92188V18H7.04188V6.8Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                  <div v-if="colIndex == 'video'">
                    <r-icon
                      v-if="col == true"
                      icon="selected"
                      fill="rocky"
                      class="ml-8"
                    />
                  </div>
                  <div v-if="colIndex == 'user'">
                    <div>
                      {{ col.name }}
                    </div>
                    <div class="types mt-1">
                      {{ col.admin }}
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="colIndex == 'public'"
                class="table_col flex align-center feta"
              >
                <drop-down-check-list
                  :key="count"
                  class="ml-4"
                  @selectId="onChangePeriodId"
                  @select="onChangePeriod"
                  :checklist="col.periodList"
                  :id="index"
                >
                  {{ col.selectedPeriod }}
                </drop-down-check-list>
              </div>
            </div>
          </div>

          <div class="footerLine" />
        </div>
      </scroll-bar>
      <not-found v-else />
      <r-modal
        ref="modal"
        close-icon
        fullscreen
      />
    </div>
  </div>
</template>

<script>
import ScrollBar from '@blackbp/vue-smooth-scrollbar';
import DropDownCheckList from '@/components/DropDownCheckList';
import Api from '@/api/Api';
import notFound from './NotFound';

export default {
  name: 'Table',
  components: {
    ScrollBar,
    DropDownCheckList,
    notFound
  },
  props: {
    headings: {
      type: [Array, Object],
      default: () => []
    },
    listItem: {
      type: [Array, Object],
      default: () => []
    },
    columnsGrid: {
      type: Array
    },
    rowLink: {
      type: String,
      default: ''
    },
    startSortedColumn: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      years: [],
      rows: [],
      currentSort: null,
      currentSortDir: 'asc',
      list: [],
      id: 0,
      count: 0
    };
  },
  computed: {
    filteredList() {
      const _this = this;

      if (_this.list.length === 0) {
        document.querySelector('#page-content.adminLayout #root').style.background = '#f6f9fe';
      } else {
        document.querySelector('#page-content.adminLayout #root').style.background = '#f6f9fe';
      }
      return _this.list;
    }
  },
  mounted() {
    this.list = this.listItem;
  },
  methods: {
    link(id) {
      const r = this.$router.resolve({
        name: 'edit-dtp',
        params: { id }
      });
      window.location.assign(r.href);
    },
    sortingClick(field) {
      if (field == this.currentSort) {
        this.currentSortDir = this.currentSortDir == 'asc' ? 'desc' : 'asc';
        console.log(this.currentSortDir);
      }
      this.currentSort = field;
      console.log(field, this.currentSort, this.currentSortDir);
      if (this.currentSort) {
        this.list.sort((a, b) => {
          let modifier = 1;

          if (this.currentSortDir == 'desc') modifier = -1;
          console.log(a);
          if (this.currentSort == 'public') {
            if (a[this.currentSort].selectedPeriod < b[this.currentSort].selectedPeriod) return -1 * modifier;
            if (a[this.currentSort].selectedPeriod > b[this.currentSort].selectedPeriod) return 1 * modifier;
          } else if (this.currentSort == 'start') {
            if (a[this.currentSort].dateTo < b[this.currentSort].dateTo) return -1 * modifier;
            if (a[this.currentSort].dateTo > b[this.currentSort].dateTo) return 1 * modifier;
          } else if (this.currentSort == 'type' || this.currentSort == 'user') {
            if (a[this.currentSort].name < b[this.currentSort].name) return -1 * modifier;
            if (a[this.currentSort].name > b[this.currentSort].name) return 1 * modifier;
          } else {
            if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
            if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
          }
          return 0;
        });
      }
    },
    onChangePeriodId(id) {
      const _this = this;

      _this.id = id;
    },
    onChangePeriod(e) {
      const _this = this;
      if (e.id === 'public') {
        _this.list[_this.id].public.items.details.public = true;
      } else {
        _this.list[_this.id].public.items.details.public = false;
      }
      _this.list[_this.id].public.items.approved = 1;
      const mod = _this.list[_this.id].public.items.details.moderation;
      _this.list[_this.id].public.items.details.moderation = false;
      const json = _this.list[_this.id].public.items;
      const api = new Api();
      api.getSaveIdApprove(json).then(results => {
        api.getSavePub(json).then(result => {
        });
      });
      _this.list[_this.id].public.periodList = _this.list[_this.id].public.periodList.map(el => ({
        ...el,
        active: el.value === e.value
      }));
      _this.list[_this.id].public.selectedPeriod = e.value;
      _this.count++;
      if (mod == true) {
        const r = this.$router.resolve({
          name: 'mod',
          query: { q: 'moderation' }
        });

        window.location.assign(r.href);
      }
    }
  }
};
</script>

<style scoped lang="scss">
.relative {
  position: relative;
}

::v-deep .subtitle {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.table {
  width: calc(100% + 32px + 40px);
  position: relative;
  left: -32px;
  overflow: hidden;

  &_col {

    color: #04153e;

    .overflow {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.btn {
  display: table;
  background: rgb(255, 255, 255);
  min-width: calc(100% - 25.3%);
  min-height: calc(100% - 96px);
  margin-left: -32px;
}

.rir-tooltip {
  position: relative;
}

.rir-tooltip__wrapper--arrow {
  position: absolute;
  top: 100%;
  left: 12px;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid;
  background-color: transparent !important;
}

.rir-tooltip__wrapper {
  position: absolute;
  top: calc(100% + 6px);
  border-radius: 12px;
  padding: 4px 8px;
  left: 0;
  right: 0;
  z-index: 100;
  filter: drop-shadow(0 4px 16px rgba(48, 3, 14, 0.16));
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  font-size: 13px;
  line-height: 16px;
}

.table_row {
  display: grid !important;
  grid-gap: 24px;
  align-items: center;
  align-content: center;
  padding: 16px 40px 16px 32px;
  border-top: 1px solid #e4edfb;
  position: relative;
  width: max-content;
  min-width: 100%;

  &.hidden {
    overflow: hidden;
  }

  &__delete {
    position: absolute;
    right: 0;
    bottom: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f6f9fe;
    box-shadow: 0px 2px 8px rgba(4, 21, 62, 0.12);
    height: 100%;
    width: 70px;
    transform: translateX(100%);
    transition: 0.2s ease-in-out;
    transition-delay: 0.3s;
    cursor: pointer;
    opacity: 0;

    .rir-icon {
      transition: 0.2s ease-in-out;
    }

    &:hover {
      .rir-icon {
        opacity: 1;
      }
    }
  }

  &:hover {
    .table_row__delete {
      opacity: 1;
      transform: translateX(0px);
    }
  }
}

.table_body {

  .table_row {
    &.error {
      &:hover {
        .rir-tooltip__wrapper {
          opacity: 1;
        }
      }

      .rir-checkbox {
        pointer-events: none;
      }

      .rir-checkbox__input:before {
        border: 2px solid #e14761;
        opacity: 0.32;
      }
    }
  }
}

.table_head {
  width: max-content;
  min-width: 100%;

  .table_row {
    border: none;
    padding-top: 0;
    padding-bottom: 32px;
  }
}

.draggable {
  cursor: grab;
}

.draggable:active {
  cursor: grabbing;
}

.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}

.img {
  width: 76px;
}

.ghost {
  opacity: 0.3;
  //background: red;
}

::v-deep .scrollbar-track-x {
  background: #f6f9fe;
  padding: 4px 0;
  height: 20px;
  bottom: unset;
  top: 28px;

  .scrollbar-thumb {
    background: #e4edfb;
    border-radius: 4px;
    height: 12px;
    top: 4px;
    cursor: grab;

    &:active {
      cursor: grabbing;
    }

    &:before,
    &:after {
      content: '';
      background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='4' height='6' viewBox='0 0 4 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M0.5 0C0.223858 0 0 0.223858 0 0.5V5.5C0 5.77614 0.223858 6 0.5 6C0.776142 6 1 5.77614 1 5.5V0.5C1 0.223858 0.776142 0 0.5 0Z' fill='%2381ABEE'/%3e%3cpath d='M3.5 0C3.22386 0 3 0.223858 3 0.5V5.5C3 5.77614 3.22386 6 3.5 6C3.77614 6 4 5.77614 4 5.5V0.5C4 0.223858 3.77614 0 3.5 0Z' fill='%2381ABEE'/%3e%3c/svg%3e ");
      width: 4px;
      height: 6px;
      background-repeat: no-repeat;
      background-position: center center;
      position: absolute;
      top: 50%;
      margin-top: -3px;
    }

    &:before {
      left: 4px;
    }

    &:after {
      right: 4px;
    }
  }
}

.rir-tooltip {
  &__wrapper {
    background-color: var(--rir-amelie);
    filter: drop-shadow(0px 4px 16px rgba(4, 21, 62, 0.16));
    left: -10px;
    top: calc(100% + 10px);
    max-width: 345px;
    pointer-events: none;
    opacity: 0;
    transition: 0.2s ease-in-out;

    &--arrow {
      border-bottom-color: var(--rir-amelie);
      border-top-color: var(--rir-amelie);
      z-index: 100;
      top: -6px;
    }
  }
}

.pb-100px {
  padding-bottom: 100px;
}

::v-deep .c-scroll-view {
}

.table_row {
  &.disabled {
    .feta {
      opacity: .48;
    }
  }

  &.error {
    background: #FEF8F9;
  }
}

::v-deep .rir-tooltip__wrapper {
  bottom: calc(100% + 6px);
  top: unset !important;
  max-width: 260px;
  text-align: left;
}

.types {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #04153E;
  opacity: 0.48;
}
.photo {
  margin-top: 7px;
  width:72px;
  height:72px;
  overflow:hidden;
  border-radius: 8px;
}
.photo_img {
  height: 72px;
  border-radius: 8px;
}
.footerLine {
  display: table;
  width: 1708px;
  height: 300px;
}
.over {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
</style>

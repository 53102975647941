import ApiService from './ApiService';

export default class Api extends ApiService {
  async getUser() {
    const { data } = await this._axios.get('/nginxApi.php?get=user');

    return data;
  }

  async getSave(value) {
    const item = value;
    const { data } = await this._axios.post('/ajax.php?action=operateAccident', {
      action: 'updateJson',
      item
    });

    return data;
  }

  async getDelete(value) {
    const JSON = {
      action: 'purge',
      id: Number(value)
    };
    const { data } = await this._axios.post('/ajax.php?action=operateAccident', JSON);

    return data;
  }

  async getSaveId(value) {
    const item = value;
    const { data } = await this._axios.post('/ajax.php?action=operateAccident', {
      action: 'updateJson',
      id: item.id,
      item
    });

    return data;
  }

  async getSaveIdApprove(value) {
    const item = value;
    const { data } = await this._axios.post('/ajax.php?action=operateAccident', {
      action: 'approve',
      id: item.id,
      approved: 1
    });

    return data;
  }

  async getSavePub(value) {
    const item = value;
    const { data } = await this._axios.post('/ajax.php?action=operateAccident', {
      action: 'updateJson',
      id: item.id,
      item
    });

    return data;
  }

  async getAccidens() {
    const JSON = {
      all: 1
    };
    const { data } = await this._axios.post('/ajax.php?action=getAccidens',
      JSON);

    return data;
  }

  async setPrintItem() {
    const JSON = {
      print: 1,
      all: 1
    };
    const { data } = await this._axios.post('/ajax.php?action=getAccidens',
      JSON);

    return data;
  }

  async getAccidensId(JSON) {
    const { data } = await this._axios.post('/ajax.php?action=getAccidens',
      JSON);

    return data;
  }

  async getAddress(payload) {
    const { data } = await this._axios.post('/mob_ajax.php?action=geosearch', payload);
    return data;
  }
}

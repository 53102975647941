<template>
  <div id="app">
    <router-view />
    <r-notification />
  </div>
</template>

<script>
export default {
  name: 'DtpAdminApp',
  beforeCreate() {
    // Удаляем script от пред. приложения
    document.getElementById('vue-yandex-maps')
      && document.getElementById('vue-yandex-maps').remove();

    // Удаляем ymaps
    window.ymaps && delete window.ymaps;
  },
  async created() {
  }
};
</script>
<style scoped>
</style>
<style>
.RButtonSimple.RFile__actions.monithei.transparent.fargo {
  background-color: unset !important;
}
.RBulb__badge.fargo.marina.eluno.title.block {
  background-color: unset !important;
}
.RButtonSimple.RModal__close.larishae.transparent.rocky {
  background-color: unset !important;
}
.RButtonAction.rocky.sulguni {
  background-color: unset !important;
}

.matrix {
  background-color: unset !important;
}
</style>

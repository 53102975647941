<template>
  <div class="objects">
    <admin-header
      title="Дорожные события"
      :back="false"
    />
    <r-tabs
      class="mt-7 mb-6"
      @input="clickTab"
      :items="[{
          id: 'dtp',
          title: 'ДТП и работы'
        },{
            id: 'moderation',
            title: 'На модерации',
            optionsBulb: {
              color: 'matrix',
              isShape: false,
              title: countComm?.length || 0,
              position: 'eluno'
            }
        }]"
      v-model="activeTabId"
    />
    <div
      class="flex mb-6"
      v-if="activeTabId.id == 'dtp'"
    >
      <router-link
        class="flex align-center sulguni rocky--text mr-6"
        to="new-dtp"
      >
        <r-icon
          class="mr-2"
          icon="add"
        />
        <div style="margin-top: -2px">
          Добавить вручную
        </div>
      </router-link>
      <div
        class="flex align-center sulguni rocky--text mr-6 pointer"
        @click="graph"
      >
        <r-icon
          class="mr-2"
          icon="chart"
        />
        <div style="margin-top: -2px">
          Статистика ДТП
        </div>
      </div>
      <button
        transparent
        class="flex align-center sulguni rocky--text pointer"
        @click="printItemClick"
      >
        <r-icon
          class="mr-2"
          icon="load"
        />
        <div style="margin-top: -2px">
          Скачать в XLS
        </div>
      </button>
    </div>
    <div class="flex mb-6">
      <r-select
        class="flex-1 mr-6"
        :items="sortTypes"
        label="Тип"
        @input="clickCheck()"
        v-model="selectedSortType"
      />
      <r-date-picker
        label="Дата с"
        v-model="filterStartDate"
        @input="clickCheck()"
        :is-clear-model="true"
        :click-close="true"
      />
      <r-date-picker
        class="ml-4"
        label="Дата по"
        v-model="filterEndDate"
        @input="clickCheck()"
        :is-clear-model="true"
        :click-close="true"
      />
      <r-select
        class="flex-1 ml-6 mr-6"
        :items="sortTypesLaw"
        label="Пользователь"
        v-model="selectedSortTypeLaw"
        @input="clickCheck()"
      />
      <r-input
        class="flex-1 mr-6"
        label="Поиск"
        v-model="search"
        :is-clear="true"
        before-icon="search"
        :is-clear-model-string="true"
      />
      <r-button-simple
        size="larishae"
        icon="icon-view"
        icon-size="20"
        :key="'btn_'+count"
        @click="mapClick('table')"
        :type="selectedView == 'map' ? 'secondary' : 'primary'"
      />
      <r-button-simple
        v-show="activeTabId.id !== 'moderation'"
        size="larishae"
        class="ml-2"
        icon="geopoint"
        :key="'btn1_'+count"
        :type="selectedView == 'map' ? 'primary' : 'secondary'"
        icon-size="20"
        @click="mapClick('map')"
      />
    </div>
    <loader v-if="isLoading" />
    <div v-else>
      <template>
        <div class="flex mb-4 align-center">
          <!-- <rir-checkbox
            v-if="activeTabId === 'dtp'"
            v-model="isActive"
            @input="clickCheck()"
            label="Неактуальные события"
          /> -->
          <r-checkbox
            v-if="selectedView === 'map' && activeTabId.id == 'dtp'"
            :value="clickMap"
            @input="clickMapCheck"
            title="Тепловая карта ДТП"
          />
          <div class="ml-auto parmigiano text-titanic opacity-48">
            {{ !!searchText ? 'Найдено' : 'Всего' }} {{ countObjects }}
          </div>
        </div>
      </template>
      <tab-dtp
        :key="count"
        :items="filteredList"
        v-if="activeTabId.id == 'dtp' && selectedView === 'table'"
      />

      <tab-moderation
        :key="count"
        :items="filteredList"
        v-if="activeTabId.id == 'moderation' && selectedView === 'table'"
      />
      <div
        class="objects__map"
        v-if="selectedView === 'map' && activeTabId.id == 'dtp' && clickMap == true"
      >
        <div
          id="YMapsID"
          :class="['rir-map', { 'rir-map--fixed': !isCollapsed }]"
          ref="map"
        >
          <div class="zoom">
            <r-button-simple
              style="display: block;"
              class="mb-4 amelie"
              :icon="isCollapsed ? 'fullscreen' : 'exit-fullscreen'"
              fill="rocky"
              icon-size="20"
              @click="clickFull"
              type="light"
              size="larishae"
              title=""
            />
            <r-button-simple
              style="display: block;"
              type="light"
              size="larishae"
              icon="add"
              class="amelie"
              fill="rocky"
              icon-size="20"
              @click="onZoom(1)"
              title=""
            />
            <r-button-simple
              style="display: block;"
              type="light"
              size="larishae"
              class="mt-1 mb-4 amelie"
              icon="remove"
              fill="rocky"
              icon-size="20"
              @click="onZoom(-1)"
              title=""
            />
          </div>
        </div>
      </div>
      <div
        v-if="selectedView === 'map' && (clickMap == false || activeTabId.id == 'moderation')"
        class="objects__map"
        ref="map"
      >
        <rir-map
          v-if="isShowMap"
          :key="count"
          show-type
          search
          @input="searchAddress"
          :value="searchText"
          @click="onMapClick"
          @mapInit="initMap"
          @markersWasAdd="markersWasAdd"
          :balloon-component="BalloonCard"
          :ball="true"
        >
          <div v-if="filteredMap.length > 0">
            <ymap-marker
              v-for="(marker,index) of filteredMap"
              :key="`_${marker.id}+${index}`"
              :coords="[marker.lat, marker.lng]"
              :marker-id="`marker_${marker.id}+${index}`"
              :balloon="{header: {id: marker.id, name: marker.dtp}}"
              :icon="marker.work === 0 ?
                (marker.active ? $markerIcon('mapPin') : $markerIcon('mapPinGray')) :
                (marker.active ? $markerIcon('mapPinWork') : $markerIcon('mapPinGrayWork'))"
              :options="{
                hideIconOnBalloonOpen: false,
                iconColor: marker.active ? '#3D75E4' : '#A2ABBE'
              }"
              cluster-name="main"
              :balloon-component-props="{
                marker: {
                  ...marker,
                  id: marker.id
                },
              }"
            />
          </div>
        </rir-map>
      </div>
    </div>
    <graph ref="childGraph" />
    <model />
  </div>
</template>

<script>
import AdminHeader from '@/components/AdminHeader.vue';
import TabModeration from '@/components/TabModeration';
import RirMap from '@/components/RirMap';
import BalloonCard from '@/components/BalloonCard';
import Graph from '@/components/Graph.vue';
import Model from '@/components/Model.vue';
import Loader from '@/components/Loader.vue';
import { loadYmap } from '../plugins/vue-yandex-maps/vue-yandex-maps.umd';
import TabDtp from '../components/TabDtp';
import Api from '../api/Api';

export default {
  name: 'ObjectList',
  components: {
    Loader,
    BalloonCard,
    AdminHeader,
    TabDtp,
    TabModeration,
    RirMap,
    Graph,
    Model
  },
  data() {
    return {
      countComm: 0,
      selectedType: [
        {
          id: 0,
          value: 'ДТП'
        },
        {
          id: 1,
          value: 'Работы'
        }
      ],
      selectedWork: [
        {
          id: 0,
          value: ''
        },
        {
          id: 1,
          value: 'Дорожные работы'
        },
        {
          id: 2,
          value: 'Неработающий светофор'
        },
        {
          id: 3,
          value: 'Разбитый асфальт/Грунтовая дорога'
        },
        {
          id: 4,
          value: 'Плановое перекрытие'
        },
        {
          id: 5,
          value: 'Другое'
        }
      ],
      selectedDtp: [
        {
          id: 0,
          value: ''
        },
        {
          id: 1,
          value: 'Наезд на стоящее ТС'
        },
        {
          id: 2,
          value: 'Столкновение'
        },
        {
          id: 3,
          value: 'Наезд на препятствие'
        },
        {
          id: 4,
          value: 'Наезд на пешехода'
        },
        {
          id: 5,
          value: 'Наезд на велосипедиста'
        },
        {
          id: 6,
          value: 'Наезд на животное'
        },
        {
          id: 7,
          value: 'ДТП с перевозимым грузом'
        },
        {
          id: 8,
          value: 'Иное ДТП'
        }
      ],
      clickMap: false,
      activeTabId: {
        id: 'dtp',
        title: 'ДТП и работы'
      },
      tabs: [
        {
          id: 'dtp',
          title: 'ДТП и работы'
        }
      ],
      count: 0,
      searchText: null,
      timeout: null,
      completedWorks: false,
      selectedView: 'table',
      selectedSortType: 0,
      cadastralNum: '',
      isActive: false,
      list: [],
      map: null,
      isCollapsed: true,
      filterStartDate: null,
      filterEndDate: null,
      sortTypes: [
        {
          id: 0,
          title: 'Все'
        },
        {
          id: 1,
          title: 'ДТП'
        },
        {
          id: 2,
          title: 'Работы'
        }
      ],
      mod: 0,
      isShowMap: true,
      mapZoom: 12,
      myMap: null,
      years: [],
      contractsForMap: [],
      selectedSortTypeLaw: 0,
      sortTypesLaw: [
        {
          id: 0,
          title: 'Все'
        },
        {
          id: 1,
          title: 'Житель'
        },
        {
          id: 2,
          title: 'Администратор'
        }
      ]
    };
  },
  computed: {
    search: {
      get() {
        return this.searchText;
      },
      set(val) {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.searchText = val;
          this.clickCheck();
        }, 420);
      }
    },
    BalloonCard() {
      return BalloonCard;
    },
    isLoading() {
      return this.$store.state.isObjectLoading;
    },
    filteredMap() {
      const _this = this;
      let dtp = null;
      let list = {};

      if (!_this.$store.state?.all?.length) return [];
      const objects = this.$store.state.all;
      list = objects.map(el => {
        if (el?.details?.typeWork >= 0) {
          if (el?.details?.typeWork === 0) {
            dtp = _this.selectedDtp.filter(
              els => (els.id === el?.details?.selectWorkAndDtp)
            );
          } else {
            dtp = _this.selectedWork.filter(
              els => (els.id === el?.details?.selectWorkAndDtp)
            );
          }
          return {
            lat: el.lat,
            lng: el.lng,
            id: el.id,
            work: el.details.typeWork,
            public: el.details.public,
            moderation: el?.details?.moderation ? el.details.moderation : false,
            photo: el?.details?.images?.length > 0 ? el.details.images[0].url : false,
            address: el?.details?.address?.length > 0 ? el.details.address : '',
            dtp: dtp[0]?.value,
            date: el.details.dates,
            start: {
              dateTo: el.details.dates
            },
            admin: el?.details?.admin === true,
            active: el?.active === 1
          };
        }
      });

      list = list.filter(el => (el !== undefined));
      if (_this.selectedSortType > 0) {
        list = list.filter(el => (el.work === _this.selectedSortType - 1));
      }
      if (_this.selectedSortTypeLaw === 1) {
        list = list.filter(
          el => (el?.admin === false)
        );
      }
      if (_this.selectedSortTypeLaw === 2) {
        list = list.filter(
          el => (el?.admin === true)
        );
      }
      if (this.searchText) {
        list = list.filter(el => (el?.address
          ? this.onAddress(el.address)
          : false));
      }
      list = _this.dateToFrom(list);
      if (_this.activeTabId.id == 'moderation') {
        list = list.filter(
          el => (el?.moderation === true)
        );
      } else {
        list = list.filter(
          el => (el?.moderation === false)
        );
      }

      /* if (this.isActive === false && this.activeTabId !== 'moderation') {
        list = list.filter(
          el => (el?.active == true)
        );
      } */
      this.count++;

      return list;
    },
    filteredList() {
      const _this = this;
      let dtp = null;

      if (!_this.$store.state?.all?.length) return [];
      const objects = _this.$store.state.all;
      _this.list = objects.map(el => {
        const type = _this.selectedType.filter(
          els => (els.id === el?.details?.typeWork)
        );
        if (el?.details?.typeWork === 0) {
          dtp = _this.selectedDtp.filter(
            els => (els.id === el?.details?.selectWorkAndDtp)
          );
        } else {
          dtp = _this.selectedWork.filter(
            els => (els.id === el?.details?.selectWorkAndDtp)
          );
        }

        return {
          photo: el?.details?.images.length > 0 ? el.details.images[0].url : '',
          type: {
            id: type[0]?.id,
            name: dtp[0]?.value,
            type: type[0]?.value
          },
          address: el?.details?.address?.length > 0 ? el.details.address : '',
          start: {
            date: el?.details?.dates ? this.formatDate(el.details.dates) : '',
            time: el?.details?.dates ? this.formatTime(el.details.dates) : '',
            dateTo: el?.details?.dates
          },
          public: {
            items: el,
            moderation: el?.details?.moderation ? el.details.moderation : false,
            selectedPeriod: el?.details?.public !== true ? 'Не опубликовано' : 'Опубликовано',
            periodList: [
              { id: 'public', active: el?.details?.public === true, value: 'Опубликовано' },
              { id: 'notPublic', active: el?.details?.public !== true, value: 'Не опубликовано' }
            ]
          },
          status: el?.details?.selectStatus >= 0 ? el.details.selectStatus : false,
          video: el?.details?.video.length > 0,
          user: {
            active: el?.active === 1,
            name: el?.details?.admin === true ? el?.author : (el?.details?.fio ? el.details.fio : '-'),
            admin: el?.details?.admin === true ? 'Администратор' : 'Житель',
            adminInfo: el?.details?.admin === true
          }
        };
      });
      _this.countComm = _this.list;
      _this.countComm = _this.countComm.filter(
          el => (el?.public?.moderation == true));

      if (_this.selectedSortType > 0) {
        _this.list = _this.list.filter(
          el => (el?.type?.id === _this.selectedSortType - 1)
        );
      }
      if (_this.selectedSortTypeLaw === 1) {
        _this.list = _this.list.filter(
          el => (el?.user?.adminInfo === false)
        );
      }
      if (_this.selectedSortTypeLaw === 2) {
        _this.list = _this.list.filter(
          el => (el?.user?.adminInfo === true)
        );
      }
      if (this.searchText) {
        _this.list = _this.list.filter(el => (el?.address
          ? this.onAddress(el.address)
          : false));
      }

      /* if (_this.isActive === false && this.activeTabId !== 'moderation') {
        _this.list = _this.list.filter(
          el => (el?.user?.active === true)
        );
      } */

      _this.list = _this.dateToFrom(_this.list);
      if (_this.activeTabId.id == 'moderation') {
        _this.list = _this.list.filter(
          el => (el?.public?.moderation === true)
        );
      } else {
        _this.list = _this.list.filter(
          el => (el?.public?.moderation === false)
        );
      }

      return _this.list;
    },
    countObjects() {
      return this.list?.length || 0;
    }
  },
  async mounted() {
    const _this = this;
    if (_this.$route.query.q == 'moderation') {
      _this.activeTabId = {
        id: 'moderation',
        title: 'На модерации'
      };
    }
    await _this.$store.dispatch('getAll').then(item => {
      _this.tabs = _this.tabs.map(el => {
        _this.mod = item.all.filter(el => (el?.details?.moderation === true))?.length;
        this.count++;
        return {
          id: el.id,
          title: el.title,
          count: el.id === 'moderation' ? _this.mod : 0
        };
      });
    });
  },
  methods: {
    // Печать
    printItemClick() {
      const api = new Api();

      api.setPrintItem().then(item => {
        if (item?.url) {
          const link = document.createElement('a');
          link.setAttribute('href', item.url);
          link.setAttribute('download', item.url.split('/')[item.url.split('/').length - 1]);
          link.click();
        }
      });
    },
    graph() {
      this.$emit('update');
    },
    markersWasAdd() {
      this.centeredMap();
    },
    initMap(e) {
      this.map = e;
    },
    centeredMap() {
      if (this.map && this.filteredMap.length > 0) {
        const pointsList = [];
        if (this.filteredMap.length > 0) {
          this.filteredMap.forEach(item => {
            pointsList.push([item.lat, item.lng]);
          });
        }
        this.$nextTick(() => {
          if (pointsList.length > 0) {
            this.map.setBounds(window.ymaps.util.bounds.fromPoints(pointsList), {
              checkZoomRange: true,
              zoomMargin: 100,
              duration: 300
            });
          }
        });
      }
    },
    onAddress(address) {
      let len = 0;
      const arr = this.searchText.toLowerCase().replace(/ +/g, ' ').trim().split(' ');
      arr.forEach(item => {
        if (address.toLowerCase().indexOf(item) >= 0) {
          len++;
        }
      });
      return address
        ? len === arr.length
        : false;
    },
    dateToFrom(list) {
      if (!!this.filterStartDate || !!this.filterEndDate) {
        let start = new Date(this.filterStartDate || new Date(0));
        let end = new Date(this.filterEndDate || '01.01.3000');
        let endItem = end;

        const dateFilter = date => {
          start = new Date(start).getTime();
          end = new Date(end).getTime();
          endItem = end;
          return date >= start && endItem >= date;
        };

        list = list.filter(el => dateFilter(el?.start?.dateTo) && dateFilter(el?.start?.dateTo));
      }

      return list;
    },
    deleteStart() {
      this.filterStartDate = null;
      this.clickCheck();
    },
    deleteEnd() {
      this.filterEndDate = null;
      this.clickCheck();
    },
    formatDate(date) {
      date = new Date(date);
      let dd = date.getDate();
      if (dd < 10) dd = `0${dd}`;
      let mm = date.getMonth() + 1;
      if (mm < 10) mm = `0${mm}`;
      const yy = date.getFullYear();

      return `${dd}.${mm}.${yy}`;
    },
    formatTime(date) {
      date = new Date(date);
      let h = date.getHours();
      if (h < 10) h = `0${h}`;
      let m = date.getMinutes();
      if (m < 10) m = `0${m}`;

      return `${h}:${m}`;
    },
    clickTab() {
      this.clickCheck();
      // this.selectedView = 'table';
    },
    clickMapCheck() {
      this.clickMap = !this.clickMap;
      this.clickCheck();
    },
    async clickCheck() {
      if (this.clickMap === true) {
        const v = this;
        const settings = { lang: 'ru_RU' };
        await loadYmap(settings);
        const yandexMapScript = document.createElement('SCRIPT');
        const link = 'https://yastatic.net/s3/mapsapi-jslibs/heatmap/0.0.1/heatmap.min.js';
        yandexMapScript.setAttribute('src', link);
        document.head.appendChild(yandexMapScript);

        yandexMapScript.onload = () => {
          v.data = v.items;
          v.maps(v.data);
        };
      }

      this.count++;
    },
    maps() {
      const v = this;

      window.ymaps.ready(() => {
        if (v.myMap) {
          v.myMap.destroy();
        }
        v.myMap = new window.ymaps.Map('YMapsID', {
          center: v.$cityCenter,
          zoom: v.mapZoom,
          controls: []

        }, {
          searchControlProvider: 'yandex#search'
        });
        window.ymaps.modules.require(['Heatmap'], Heatmap => {
          const coords = v.filteredMap.map(el => [
            Number(el.lat),
            Number(el.lng)
          ]);
          const heatmap = new Heatmap(coords);
          heatmap.options.set('gradient', {
            0.1: 'rgba(128, 255, 0, 0.7)',
            0.3: 'rgba(162, 36, 25, 1)',
            0.2: 'rgba(255, 255, 0, 0.8)'
          });
          heatmap.options.set('radius', 10);
          heatmap.setMap(v.myMap);
        });
      });
    },
    onZoom(val) {
      const v = this;
      const newZoom = v.mapZoom + val;
      v.mapZoom = newZoom;
      v.myMap.setZoom(v.mapZoom);
    },
    clickFull() {
      this.isCollapsed = !this.isCollapsed;
      this.$nextTick(() => {
        this.myMap.container.fitToViewport();
      });
    },
    searchAddress(e) {
      this.searchText = e;
    },
    onMapClick() {
      this.selectedMarkerId = null;
    },
    mapClick(item) {
      this.selectedView = item;
      this.clickCheck();

      if (item === 'map' || this.filteredList.length === 0) {
        document.querySelector('#page-content.adminLayout #root').style.background = '#f6f9fe';
      } else {
        document.querySelector('#page-content.adminLayout #root').style.background = '#f6f9fe';
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.icon-close {
  cursor: pointer;
  position: absolute;
  margin-left: calc(100% - 60px);
  margin-top: -28px;
}

.icon-div {
  display: table;
  position: relative;
}

.zoom {
  position: absolute;
  z-index: 10;
  right: 16px;
  margin-top: 16px;
}

.rir-map {
  height: 100%;
  width: 100%;
  position: relative;
  border-radius: 24px;
  overflow: hidden;

  &__search {
    position: absolute;
    border-radius: 8px;
    z-index: 10;
    top: 16px;
    left: 16px;
    display: flex;
    width: 255px;
    background-color: #fff;
    box-shadow: 0 4rem 16rem rgb(4 21 62 / 16%);

    > * {
      background-color: #fff;
    }
  }

  &__controls {
    position: absolute;
    top: 16px;
    right: 16px;

    > * {
      width: 40px;
      height: 40px;
      box-shadow: 0 4px 16px rgb(4 21 62 / 16%);
    }

    &--fixed {
      position: fixed;
      right: 16px;
      top: 16px;
      z-index: 10001;
    }
  }

  &--fixed {
    background-color: #ccc;
    border-radius: unset;
    position: fixed;
    height: 100% !important;
    top: 0;
    left: 0;
    z-index: 5;
  }

  &__legend {
    padding: 8px 12px;
    background: var(--rir-amelie);
    position: absolute;
    left: 16px;
    bottom: 16px;
    border-radius: 4px;
  }
}

.objects {
  background: #f6f9fe;
  margin-left: -32px;
  margin-right: -40px;
  margin-top: -32px;
  padding-top: 32px;
  padding-left: 32px;
  padding-right: 40px;
  border-top-left-radius: 48px;
  &__filter {
    display: flex;
  }

  &__list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 4px;

    > * {
      min-width: 0;
    }
  }

  &__map {
    height: 700px;
    border-radius: 24px;
    overflow: hidden;
  }
}

.rir-popover {
  display: flex;
  align-items: center;

  .rir-icon {
    &.add-icon {
      margin-right: 8px;
    }

    &.data-download__arrow {
      margin-left: 8px;
    }
  }
}

.data-download {
  &__wrapper {
    padding: 12px;

    .rir-button {
      display: flex;
      align-items: center;

      .rir-icon {
        margin-right: 8px;
      }
    }
  }
}

::v-deep .rir-popover__content {
  padding: 8px !important;
}

::v-deep .ya-map-point {
  position: absolute;
  left: -4px;
  top: -4px;
  display: block;
  width: 8px;
  height: 8px;
  background-color: #fff;
  border: 2px solid var(--rir-rocky);
  border-radius: 50%;
}

::v-deep .ya-map-point--edge {
  cursor: pointer !important;
  position: absolute;
  left: -3px;
  top: -3px;
  display: block;
  width: 6px;
  height: 6px;
  background-color: var(--rir-rocky);
  border-radius: 50%;
  opacity: 0.8;
}

::v-deep .rir-popover__content {
  background: red;
}
::v-deep .rir-popover {
  display: table !important;
}
::v-deep .fill-rocky path{
  fill: #3D75E4!important;
}
.fill-secondary {
  fill: #ffffff!important;
}
::v-deep .ymaps-2-1-79-gotoymaps__container {
  display: none;
}
::v-deep .ymaps-2-1-79-gototech {
  display: none;
}
</style>
<style>
.rocky {
  background-color: unset;
}

.fargo {
  background-color: unset;
}

.matrix {
  background-color: unset !important;
}
</style>
